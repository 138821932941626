.loading-text {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    .loading-text-icon {
        display: inline-flex;
        margin-right: 0.3rem;
    }
}