.portfolio-grid {

    margin-top: map-get($spacers, 4);

    @media (max-width: map-get($grid-breakpoints, md)) {
        margin-top: map-get($spacers, 2);
    }
}

.portfolio-page {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-grow-1;
    @extend .bg-white;

    .portfolio-header {
        @extend .text-light;
        @extend .bg-dark;
        padding: map-get($spacers, 3) 0;

        @media (max-width: map-get($grid-breakpoints, md)) {
            h1 {
                font-size: $h5-font-size;
            }
        }

        @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
            h1 {
                font-size: $h3-font-size;
            }
        }
    }

    .portfolio-carousel {
        @extend .bg-light;
        border-top: 1px solid $card-border-color;
        border-bottom: 1px solid $card-border-color;

        img {
            @extend .d-block;
            width: 50%;
            margin: 0 25%;
        }
    }

    .portfolio-overview {
        @extend .d-flex;
        @extend .flex-row;
        @extend .flex-grow-1;

        .overview-main {
            @extend .flex-grow-1;
            @extend .p-3;
        }

        .overview-sidebar {
            @extend .p-3;
            border-left: 1px solid $card-border-color;

            @media (max-width: map-get($grid-breakpoints, lg)) {
                border-left: none;
                border-top: 1px solid $card-border-color;
            }

            .property-title {
                font-weight: bold;
                margin-bottom: 0;
            }
        }
    }
}