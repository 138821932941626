.account-setting {

    h5 {
        margin-bottom: 0;
    }

    .setting-preview {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 4rem;
        padding: 0rem 1rem;

        .setting-value {

            display: inline-flex;
            flex-direction: column;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .setting-edit {
            color: $link-color;
            margin-left: 1rem;
            
            svg {
                display: none;
                margin-right: 0.5rem;
            }
        }
    }

    .setting-preview:hover {
        background-color: map-get($theme-colors, light);

        .setting__edit {
            text-decoration: underline;

            svg {
                display: inline;
            }
        }
    }

    .setting-open {
        display: flex;
        flex-direction: column;
        background-color: lighten($blue, 45%);
        padding: 0.5rem 1rem;
        
        hr {
            border: 0; border-top: 1px solid $border-color;
            width: 100%;
        }

        label {
            font-size: small;
        }
    }
}