.app-card {
    margin: 0 auto;

    .card-footer {
        display: flex;
        flex-direction: row;
        padding: map-get($spacers, 1);

        .btn {
            flex-grow: 1;
            flex-basis: 0;
            margin: map-get($spacers, 1);
        }
    }
}