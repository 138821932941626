body {
    font-family: $font-family;
    background-color: $light;
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}