$profile-pic-width: 10rem;
$profile-pic-height: 10rem;
$profile-pic-padding: 0.25rem;

.profile-pic-edit {
    display: flex;
    flex-direction: row;

    .preview {
        width: $profile-pic-width;
        height: $profile-pic-height;
        background-color: $white;
        padding: $profile-pic-padding;
        border: 1px solid $border-color;
        position: relative;

        .preview-img {
            width: 100%;
            height: 100%;
        }

        .upload-icon {
            display: none;
        }

        .browse-btn {
            display: none;
        }

        .img-overlay {
            width: $profile-pic-width - $profile-pic-padding;
            height: $profile-pic-height - $profile-pic-padding;
            background-color: white;
            opacity: 0.9;
            position: absolute;
            top: 1px;
            left: 1px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .dropzone-container {
        flex-grow: 1;

        .dropzone {
            min-height: 100% !important;
            border: 1px solid $border-color !important;
            border-radius: 0;
            background-color: map-get($theme-colors, 'light');

            .dropzone-text {
                font-family: $font-family;
                font-size: small;
                font-weight: bold !important;
                margin-top: 3rem !important;
                margin-bottom: 0 !important;
            }
        }
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
        flex-direction: column;
        align-items: center;

        .preview {
            cursor: pointer;

            .upload-icon {
                display: flex !important;
                position: absolute;
                bottom: 0.5rem;
                border: 1px solid $border-color;
                border-radius: 2rem;
                right: 0.5rem;
                background-color: white;
                width: 2rem;
                height: 2rem;
                padding: 0.5rem;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
            }

            .browse-btn {
                display: block !important;
                position: absolute;
                top: $profile-pic-padding;
                left: $profile-pic-padding;
                width: $profile-pic-width - ( $profile-pic-padding * 2);
                height: $profile-pic-height - ( $profile-pic-padding * 2);
                opacity: 0;
                cursor: pointer;
            }
        }

        .dropzone-container {
            display: none;
        }
    }
}