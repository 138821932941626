.card {
    border-radius: 0;
    box-shadow: $box-shadow-md;

    .card-header {
        border-radius: 0;
    }

    .header-white {
        background-color: $white;
    }

    .header-black {
        color: $white;
        background-color: $black;
    }

    .header-primary {
        color: $white;
        background-color: map-get($theme-colors, primary);
    }
    
    .header-secondary {
        color: $white;
        background-color: map-get($theme-colors, secondary);
    }

    .header-success {
        color: $white;
        background-color: map-get($theme-colors, success);
    }

    .header-info {
        color: $white;
        background-color: map-get($theme-colors, info);
    }

    .header-warning {
        color: $white;
        background-color: map-get($theme-colors, warning);
    }

    .header-danger {
        color: $white;
        background-color: map-get($theme-colors, danger);
    }

    .header-light {
        color: $body-color;
        background-color: map-get($theme-colors, light);
    }

    .header-dark {
        color: $white;
        background-color: map-get($theme-colors, dark);
    }
}

.card-sm {
    width: $modal-sm;
}

.card-md {
    width: $modal-md;
}
.card-md.card-responsive {
    @media (max-width: map-get($grid-breakpoints, md)) { width: $modal-sm; }
    @media (min-width: map-get($grid-breakpoints, md)) { width: $modal-md; }
}

.card-lg {
    width: $modal-lg;
}
.card-lg.card-responsive {
    @media (max-width: map-get($grid-breakpoints, md)) { width: $modal-sm; }
    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) { width: $modal-md; }
    @media (min-width: map-get($grid-breakpoints, lg)) { width: $modal-lg; }
}

.card-xl {
    width: $modal-xl;
}
.card-xl.card-responsive {
    @media (max-width: map-get($grid-breakpoints, md)) { width: $modal-sm; }
    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) { width: $modal-md; }
    @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) { width: $modal-lg; }
    @media (min-width: map-get($grid-breakpoints, xl)) { width: $modal-xl; }
}

.card-sm-full {
    @media (max-width: map-get($grid-breakpoints, md)) {
        width: 100% !important;
        margin: 1rem 0 !important;
    }
}