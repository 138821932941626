$github-color: #9f7be1;

.btn, .btn:disabled {
    color: $white;
}

.btn-light, .btn-light:disabled {
    color: $body-color !important;
}

.btn:focus, .btn:active, .btn:visited {
    box-shadow: none !important;
}

.btn:disabled {
    cursor: not-allowed;
}

.btn-github, .btn-github:visited, .btn-github:focus {
    background-color: $github-color !important;
    border-color: $github-color !important;
}

.btn-github:hover, .btn-github:active {
    background-color: darken($github-color, 15%) !important;
    border-color: darken($github-color, 15%) !important;
    color: white !important;
}