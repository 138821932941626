$theme-colors: (
    primary: #ffa500
);

$link-color: #3498DB;

$enable-rounded:false;

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_variables";

:export {
    colorPrimary: map-get($theme-colors, primary);
}