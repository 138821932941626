.navbar {
    background-color: black;
    border-bottom: 0.2rem solid map-get($theme-colors, primary);
    font-size: $font-size-sm;

    .container {
        position: relative;
    
        .navbar-brand {
            margin: 0;
            padding: 0.5rem;
            padding-left: 0;
            position: absolute;
            left: 0;

            span {
                color: map-get($theme-colors, primary);
            }
        
            small {
                font-size: x-small;
                color: darkgray;
            }

            @media (max-width: map-get($grid-breakpoints, md)) {
                font-size: x-small;
                small {
                    font-size: xx-small;
                }
            }
        }

        .navbar-brand.navbar-brand-unlinked {
            position: relative;
        }

        .nav-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            justify-content: center;
        
            .nav-link-icon {
                @media (max-width: map-get($grid-breakpoints, md)) {
                    font-size: $font-size-lg;
                }
            }
        
            .nav-link-text {
                max-width: 10rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                
                @media (max-width: map-get($grid-breakpoints, md)) {
                    display: none;
                }
            }
        }

        .nav-link.active {
            .nav-link-icon {
                color: map-get($theme-colors, primary);
            }
        }

        .navbar-right {
            position: absolute;
            right: 0;
        }

        .dropdown {
            .dropdown-toggle {
                color: $navbar-dark-color;

                svg {
                    @media (max-width: map-get($grid-breakpoints, md)) {
                        font-size: $font-size-lg;
                    }
                }

                span {
                    margin: 0 0 0 0.4rem;
                    max-width: 15rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (max-width: map-get($grid-breakpoints, md)) {
                        display: none;
                    }
                }
            }

            .dropdown-toggle:hover {
                color: $navbar-dark-hover-color;
            }

            .dropdown-toggle.active {
                svg { color: map-get($theme-colors, primary); }
                span { color: $white; }
            }

            .dropdown-toggle::after {
                display: none;
            }

            .dropdown-menu {
                border-radius: 0;
                left: auto;
                right: 0;

                .dropdown-item {
                    padding: 1rem;
                    cursor: pointer;

                    .dropdown-item-icon {
                        display: inline;
                        margin-right: 0.5rem;
                        padding: 0.5rem;
                        background-color: #EEE;
                        border: 1px solid #CCC;
                    }
                }

                .dropdown-item:hover {
                    .dropdown-item-icon {
                        border-color: map-get($theme-colors, primary);
                        color: map-get($theme-colors, primary);
                    }
                }

                .dropdown-item.active {
                    background-color: $white;
                    color: $body-color;

                    .dropdown-item-icon {
                        background-color: map-get($theme-colors, primary);
                        border-color: map-get($theme-colors, primary);
                        color: $white;
                    }
                }

                .dropdown-item:active {
                    background-color: $dropdown-link-hover-bg;
                    color: $body-color;
                }

                .dropdown-item.active:hover {
                    background-color: $dropdown-link-hover-bg;
                }
            }
        }
    }
}